import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { DialogContent, DialogContentText, DialogTitle, Divider } from '@mui/material';
import { Color } from '../../../theme';
var EscalationDialogContent = function (_a) {
    var title = _a.title, titleIcon = _a.titleIcon, description = _a.description, children = _a.children;
    return (_jsxs(_Fragment, { children: [_jsx(DialogTitle, { children: _jsxs("span", { style: { display: 'flex', alignItems: 'center' }, children: [_jsx("span", { style: {
                                display: 'flex',
                                alignItems: 'center',
                                marginRight: '4px',
                                color: Color.Main
                            }, children: titleIcon }), title] }) }), _jsxs(DialogContent, { children: [_jsx(DialogContentText, { children: description }), children] }), _jsx(Divider, {})] }));
};
export default EscalationDialogContent;
