var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getConfig } from '../common/config';
var pageSize = 10;
export var responseHandler = function (response) {
    if (response.status === 401 || response.status === 403) {
        return Promise.resolve({ error: response.statusText });
    }
    return Promise.resolve(response.json());
};
var transformPaginatedResponse = function (apiResponse, meta) {
    return {
        pageSize: pageSize,
        totalElements: Number(meta.response.headers.get('X-Total-Elements')),
        totalPages: Number(meta.response.headers.get('X-Total-Pages')),
        data: apiResponse
    };
};
export var tagApi = createApi({
    reducerPath: 'tagApi',
    baseQuery: fetchBaseQuery({
        baseUrl: getConfig().tagApi,
        prepareHeaders: function (headers, _a) {
            var getState = _a.getState;
            var token = getState().auth.token;
            if (token) {
                headers.set('authorization', "Bearer ".concat(token));
            }
            headers.set('Content-Type', 'application/json');
            return headers;
        }
    }),
    tagTypes: ['Tags'],
    endpoints: function (builder) { return ({
        getTags: builder.query({
            query: function (_a) {
                var _b = _a.page, page = _b === void 0 ? 0 : _b, _c = _a.search, search = _c === void 0 ? '' : _c, _d = _a.size, size = _d === void 0 ? pageSize : _d, _e = _a.includeEntities, includeEntities = _e === void 0 ? false : _e, isStoreTag = _a.isStoreTag, includeDisplayName = _a.includeDisplayName;
                return ({
                    url: 'api/v1/tags',
                    params: {
                        page: page,
                        search: search,
                        size: size,
                        includeEntities: includeEntities,
                        isStoreTag: isStoreTag,
                        includeDisplayName: includeDisplayName
                    },
                    responseHandler: responseHandler
                });
            },
            providesTags: function (result) {
                return result
                    ? __spreadArray(__spreadArray([], result.data.map(function (_a) {
                        var id = _a.id;
                        return ({ type: 'Tags', id: id });
                    }), true), [
                        { type: 'Tags', id: 'PARTIAL-LIST' }
                    ], false) : [{ type: 'Tags', id: 'PARTIAL-LIST' }];
            },
            transformResponse: (transformPaginatedResponse)
        })
    }); }
});
export var useGetTagsQuery = tagApi.useGetTagsQuery, useLazyGetTagsQuery = tagApi.useLazyGetTagsQuery;
