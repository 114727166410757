var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { Box } from '@mui/material';
import React, { useState } from 'react';
import { useSaveTenantMutation } from '../../../../../../api/tenantApi';
import { getPcsConfig, hasValue } from '../../../../../../common/pricer-services';
import { CONTRACT_STATUS, LICENSE_TYPES } from '../../../../../../common/tenant.constans';
import AddMetadataDialog from './atoms/AddMetadataDialog';
import ContractMenu from './atoms/ContractMenu';
import UpgradeMetadataDialog from './atoms/UpgradeMetadataDialog';
import EscalationDialogue from '../../../../../../components/EscalationDialogue/EscalationDialogue';
export default function ContractButton(_a) {
    var _this = this;
    var currentTenant = _a.tenant;
    var _b = React.useState(''), errorMessage = _b[0], setErrorMessage = _b[1];
    var _c = useState(false), openRequestPcs = _c[0], setOpenRequestPcs = _c[1];
    var _d = useState(false), openUpgradePcs = _d[0], setOpenUpgradePcs = _d[1];
    var _e = useState(false), openEscalate = _e[0], setOpenEscalate = _e[1];
    var _f = useState(LICENSE_TYPES.DEMO), nextLicType = _f[0], setNextLicType = _f[1];
    var saveTenant = useSaveTenantMutation()[0];
    var ACTIONS = [
        {
            triggers: {
                contractStatuses: [
                    'REQUESTED',
                    'SIGNED_EULA',
                    'SIGNED_PO',
                    'SIGNED_SOW',
                    'SIGNED_CONTRACT'
                ],
                permissions: ['tenant:update'],
                custom: function (tenant) {
                    var conf = getPcsConfig(tenant);
                    if (!conf) {
                        return true;
                    }
                    return !hasValue(conf.pcsId) && !hasValue(conf.requestedPcsId);
                }
            },
            icon: _jsx(LocalGroceryStoreIcon, {}),
            title: 'Request Central Manager',
            execute: function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                return [2 /*return*/, setOpenRequestPcs(true)];
            }); }); }
        },
        {
            triggers: {
                contractStatuses: [
                    'SIGNED_EULA',
                    'SIGNED_SOW',
                    'SIGNED_PO',
                    'SIGNED_CONTRACT'
                ],
                permissions: ['tenant:update'],
                custom: function (tenant) {
                    var conf = getPcsConfig(tenant);
                    if (!conf) {
                        return false;
                    }
                    var hasConf = hasValue(conf.pcsId);
                    if (tenant.licenseType === LICENSE_TYPES.PRODUCTION ||
                        (tenant.licenseType === LICENSE_TYPES.STAGING &&
                            tenant.contractStatus.toLowerCase().replace('_', ' ') ===
                                CONTRACT_STATUS.SIGNED_EULA.toLowerCase())) {
                        return false;
                    }
                    return hasConf;
                }
            },
            icon: _jsx(LocalGroceryStoreIcon, {}),
            title: 'Promote central manager',
            execute: function () { return __awaiter(_this, void 0, void 0, function () {
                var openPcs;
                return __generator(this, function (_a) {
                    openPcs = false;
                    if (currentTenant.licenseType === LICENSE_TYPES.DEMO) {
                        openPcs = true;
                        setNextLicType(LICENSE_TYPES.TEST);
                    }
                    else if (currentTenant.licenseType === LICENSE_TYPES.TEST) {
                        openPcs = true;
                        setNextLicType(LICENSE_TYPES.STAGING);
                    }
                    else if (currentTenant.licenseType === LICENSE_TYPES.STAGING) {
                        setNextLicType(LICENSE_TYPES.PRODUCTION);
                        openPcs = true;
                    }
                    setOpenUpgradePcs(openPcs);
                    return [2 /*return*/];
                });
            }); }
        },
        {
            triggers: {
                contractStatuses: [
                    'REQUESTED',
                    'SIGNED_EULA',
                    'SIGNED_PO',
                    'SIGNED_SOW',
                    'SIGNED_CONTRACT'
                ],
                permissions: ['tenant:super-admin', 'tenant:terminateContract']
            },
            icon: _jsx(DeleteForeverIcon, {}),
            title: 'Terminate',
            execute: function () { return onSubmit('TERMINATED'); }
        },
        {
            triggers: {
                contractStatuses: [
                    'REQUESTED',
                    'SIGNED_EULA',
                    'SIGNED_PO',
                    'SIGNED_SOW',
                    'SIGNED_CONTRACT'
                ],
                permissions: ['pricer-profile:only', 'pricer-viewer:only']
            },
            icon: _jsx(LockOpenIcon, {}),
            title: 'Elevate access',
            execute: function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                return [2 /*return*/, setOpenEscalate(true)];
            }); }); }
        }
    ];
    var onSubmit = function (contractStatus) { return __awaiter(_this, void 0, void 0, function () {
        var e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, saveTenant(__assign(__assign({}, currentTenant), { contractStatus: contractStatus })).unwrap()];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    console.error('Failed to save: ', e_1);
                    setErrorMessage('Failed to save tenant');
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs(Box, { children: [_jsx(ContractMenu, { actions: ACTIONS, tenant: currentTenant, error: errorMessage, onCloseError: function () { return setErrorMessage(''); } }), _jsx(AddMetadataDialog, { tenant: currentTenant, open: openRequestPcs, onClose: function () { return setOpenRequestPcs(false); } }), _jsx(UpgradeMetadataDialog, { licenseType: nextLicType, tenant: currentTenant, open: openUpgradePcs, onClose: function () { return setOpenUpgradePcs(false); } }), _jsx(EscalationDialogue, { title: "Permission settings", open: openEscalate, onClose: function () { return setOpenEscalate(false); }, description: "Please change your permission setting to get a temporary permission key for 8 hours" })] }));
}
