var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AdminPanelSettingsRounded, AssessmentRounded, BorderColorRounded, BrushRounded, BusinessCenterRounded, InstallDesktopRounded, LocalGroceryStoreRounded, ManageAccountsRounded, PersonPinRounded, StoreRounded, TagRounded, VisibilityRounded } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControlLabel, FormGroup, IconButton, Radio, RadioGroup, TextField } from '@mui/material';
import { useRef, useState } from 'react';
import { useAuthentication } from '../../common/hooks/authentication';
import { Color } from '../../theme';
import EscalationDialogContent from './atoms/EscalationDialogContent';
import { TagDropdown } from './atoms/TagDropdown';
var PRICER_ROLES = [
    {
        label: 'Admin',
        value: 'pricer-admin',
        icon: _jsx(ManageAccountsRounded, {})
    },
    {
        label: 'Editor',
        value: 'pricer-editor',
        icon: _jsx(BorderColorRounded, {})
    },
    {
        label: 'Viewer',
        value: 'pricer-viewer',
        icon: _jsx(VisibilityRounded, {})
    }
];
var PROFESSIONAL_ROLES = [
    {
        label: 'Tenant Admin',
        value: 'tenant-admin',
        icon: _jsx(StoreRounded, {})
    },
    {
        label: 'Business Admin',
        value: 'business-admin',
        icon: _jsx(PersonPinRounded, {})
    },
    {
        label: 'Status & Metrics',
        value: 'metrics',
        icon: _jsx(AssessmentRounded, {})
    }
];
var TAG_ROLES = [
    {
        label: 'Store Manager',
        value: 'store-manager',
        icon: _jsx(StoreRounded, {})
    },
    {
        label: 'Store Worker',
        value: 'store-worker',
        icon: _jsx(LocalGroceryStoreRounded, {})
    },
    {
        label: 'Installer',
        value: 'installer',
        icon: _jsx(InstallDesktopRounded, {})
    },
    {
        label: 'Status & Metrics',
        value: 'metrics-tag',
        icon: _jsx(AssessmentRounded, {})
    },
    {
        label: 'Designer',
        value: 'designer',
        icon: _jsx(BrushRounded, {})
    }
];
var ROLE_TYPE;
(function (ROLE_TYPE) {
    ROLE_TYPE[ROLE_TYPE["PRICER"] = 0] = "PRICER";
    ROLE_TYPE[ROLE_TYPE["PROFESSIONAL"] = 1] = "PROFESSIONAL";
    ROLE_TYPE[ROLE_TYPE["TAG"] = 2] = "TAG";
})(ROLE_TYPE || (ROLE_TYPE = {}));
var EscalationDialogue = function (_a) {
    var onClose = _a.onClose, open = _a.open, title = _a.title, description = _a.description;
    var elevate = useAuthentication().elevate;
    var _b = useState(null), tag = _b[0], setTag = _b[1];
    var _c = useState([]), roles = _c[0], setRoles = _c[1];
    var _d = useState(''), reason = _d[0], setReason = _d[1];
    var radioGroupRef = useRef(null);
    var handleOk = function () { return __awaiter(void 0, void 0, void 0, function () {
        var elevationRoles;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    handleClose();
                    elevationRoles = roles
                        .map(function (r) { return (r === TAG_ROLES[3].value ? 'metrics' : r); })
                        .map(function (r) { return r.replace('pricer-', ''); })
                        .toString();
                    return [4 /*yield*/, elevate(elevationRoles, reason, tag && tag.id)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var handleClose = function () {
        onClose();
        setReason('');
        setTag(null);
        setRoles([]);
    };
    var disableButton = function () {
        if (roles.length === 0) {
            return true;
        }
        if (!reason) {
            return true;
        }
        if (TAG_ROLES.map(function (r) { return r.value; }).includes(roles[0]) && !tag) {
            return true;
        }
        return false;
    };
    var handleEntering = function () {
        if (radioGroupRef.current != null) {
            radioGroupRef.current.focus();
        }
    };
    var handleSelection = function (roleType, value, checked) {
        if (checked === void 0) { checked = false; }
        var newRoles = [];
        switch (roleType) {
            case ROLE_TYPE.PRICER:
                newRoles = [value];
                setTag(null);
                break;
            case ROLE_TYPE.PROFESSIONAL:
                newRoles = __spreadArray(__spreadArray([], roles.filter(function (v) {
                    return PROFESSIONAL_ROLES.map(function (r) { return r.value; }).includes(v);
                }), true), [
                    value
                ], false);
                setTag(null);
                break;
            case ROLE_TYPE.TAG:
                newRoles = __spreadArray(__spreadArray([], roles.filter(function (v) { return TAG_ROLES.map(function (r) { return r.value; }).includes(v); }), true), [
                    value
                ], false);
                break;
        }
        if (roleType === ROLE_TYPE.PRICER || checked) {
            console.log('SETTING NEW ROLES: ', newRoles);
            setRoles(newRoles);
        }
        else {
            setRoles(roles.filter(function (role) { return role !== value; }));
        }
    };
    return (_jsxs(Dialog, { maxWidth: "xs", scroll: 'body', TransitionProps: { onEntering: handleEntering }, open: open, onClose: handleClose, children: [_jsx(IconButton, { sx: {
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: Color.Main
                }, onClick: handleClose, children: _jsx(CloseIcon, { fontSize: "inherit" }) }), _jsx(DialogTitle, { fontWeight: 'bold', id: "alert-dialog-title", children: title }), _jsx(DialogContent, { children: _jsx(DialogContentText, { maxWidth: '18.75rem', id: "alert-dialog-description", children: description }) }), _jsx(Divider, {}), _jsx(EscalationDialogContent, { title: "Pricer Roles", titleIcon: _jsx(AdminPanelSettingsRounded, {}), children: _jsx(RadioGroup, { ref: radioGroupRef, value: roles[0] || '', onChange: function (e, value) { return handleSelection(ROLE_TYPE.PRICER, value); }, children: PRICER_ROLES.map(function (option) { return (_jsx(FormControlLabel, { value: option.value, control: _jsx(Radio, {}), label: _jsxs("span", { style: { display: 'flex', alignItems: 'center' }, children: [_jsx("span", { style: {
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginRight: '4px',
                                        color: Color.Main
                                    }, children: option.icon }), option.label] }) }, option.value)); }) }) }), _jsx(EscalationDialogContent, { title: "Professional Roles", titleIcon: _jsx(BusinessCenterRounded, {}), children: _jsx(FormGroup, { children: PROFESSIONAL_ROLES.map(function (option) { return (_jsx(FormControlLabel, { onChange: function (_e, checked) {
                            return handleSelection(ROLE_TYPE.PROFESSIONAL, option.value, checked);
                        }, checked: roles.includes(option.value), value: option.value, control: _jsx(Checkbox, {}), label: _jsxs("span", { style: { display: 'flex', alignItems: 'center' }, children: [_jsx("span", { style: {
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginRight: '4px',
                                        color: Color.Main
                                    }, children: option.icon }), option.label] }) }, option.value)); }) }) }), _jsxs(EscalationDialogContent, { title: "Tag Roles", titleIcon: _jsx(TagRounded, {}), children: [_jsx(FormGroup, { children: TAG_ROLES.map(function (option) { return (_jsx(FormControlLabel, { onChange: function (_e, checked) {
                                return handleSelection(ROLE_TYPE.TAG, option.value, checked);
                            }, checked: roles.includes(option.value), value: option.value, control: _jsx(Checkbox, {}), label: _jsxs("span", { style: { display: 'flex', alignItems: 'center' }, children: [_jsx("span", { style: {
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginRight: '4px',
                                            color: Color.Main
                                        }, children: option.icon }), option.label] }) }, option.value)); }) }), _jsx(Box, { sx: { marginTop: '1rem', marginX: '1rem' }, children: _jsx(TagDropdown, { value: tag, onChange: function (value) {
                                setTag(value);
                                setRoles(__spreadArray([], roles.filter(function (v) {
                                    return TAG_ROLES.map(function (r) { return r.value; }).includes(v);
                                }), true));
                            }, label: '' }) })] }), _jsx(Box, { marginY: '1rem', display: 'flex', justifyContent: 'center', children: _jsx(TextField, { value: reason, onChange: function (e) { return setReason(e.target.value); }, size: "small", label: "Reason for escalation" }) }), _jsxs(DialogActions, { sx: { justifyContent: 'center' }, children: [_jsx(Button, { autoFocus: true, onClick: handleClose, children: "Cancel" }), _jsx(Button, { sx: {
                            ':disabled': {
                                backgroundColor: '#bbb'
                            }
                        }, disabled: disableButton(), onClick: handleOk, children: "Escalate" })] })] }));
};
export default EscalationDialogue;
