var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ListItemText, MenuItem, TextField } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useLazyGetTagsQuery } from '../../../api/tagApi';
import Loading from '../../molecules/Loading';
export var TagDropdown = function (_a) {
    var value = _a.value, onChange = _a.onChange, label = _a.label, _b = _a.disabled, disabled = _b === void 0 ? false : _b, _c = _a.skip, skip = _c === void 0 ? false : _c, children = _a.children, _d = _a.disabledTags, disabledTags = _d === void 0 ? [] : _d, _e = _a.additionalTags, additionalTags = _e === void 0 ? [] : _e, sx = _a.sx, inputProps = _a.inputProps, InputLabelProps = _a.InputLabelProps;
    var _f = useState(additionalTags), tags = _f[0], setTags = _f[1];
    var _g = useState(0), nextPage = _g[0], setNextPage = _g[1];
    var _h = useState(undefined), maxPages = _h[0], setMaxPages = _h[1];
    var _j = useLazyGetTagsQuery(), TagTrigger = _j[0], isFetching = _j[1].isFetching;
    var ref = useRef(null);
    var _k = useState({ width: 0, maxWidth: 0 }), dimensions = _k[0], setDimensions = _k[1];
    useEffect(function () {
        if (ref.current) {
            setDimensions({
                width: ref.current.offsetWidth,
                maxWidth: ref.current.offsetWidth
            });
        }
    }, []);
    var loadTags = function () { return __awaiter(void 0, void 0, void 0, function () {
        var _a, data, totalPages, rawTags, combinedTags, uniqueTags;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, TagTrigger({
                        page: 0,
                        includeEntities: false,
                        includeDisplayName: true,
                        size: 10
                    }, true).unwrap()];
                case 1:
                    _a = _b.sent(), data = _a.data, totalPages = _a.totalPages;
                    setNextPage(1);
                    setMaxPages(totalPages);
                    rawTags = data.map(function (tag) {
                        return __assign(__assign({}, tag), { role: '' });
                    });
                    if (value &&
                        !rawTags.find(function (tag) { return tag.tag === value.tag; }) &&
                        !additionalTags.find(function (tag) { return tag.tag === value.tag; })) {
                        rawTags.unshift(value);
                    }
                    combinedTags = __spreadArray(__spreadArray([], additionalTags, true), rawTags, true);
                    uniqueTags = Array.from(new Set(combinedTags.map(function (tag) { return tag.tag; })))
                        .map(function (tag) {
                        return combinedTags.find(function (a) { return a.tag === tag; });
                    })
                        .filter(function (tag) { return !!tag; });
                    setTags(uniqueTags);
                    return [2 /*return*/];
            }
        });
    }); };
    var loadMoreTags = function () { return __awaiter(void 0, void 0, void 0, function () {
        var data, rawTags, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    if (!maxPages || isFetching || nextPage > maxPages)
                        return [2 /*return*/];
                    return [4 /*yield*/, TagTrigger({
                            page: nextPage,
                            includeEntities: false,
                            includeDisplayName: true,
                            size: 10
                        }, true).unwrap()];
                case 1:
                    data = (_a.sent()).data;
                    setNextPage(nextPage + 1);
                    rawTags = data.map(function (tag) {
                        return __assign(__assign({}, tag), { role: '' });
                    });
                    setTags(__spreadArray(__spreadArray([], tags, true), rawTags.filter(function (tag) { return tag.tag !== (value === null || value === void 0 ? void 0 : value.tag); }), true));
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    console.error('Failed to load more tags', e_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs(TextField, { ref: ref, size: "small", fullWidth: true, label: label, disabled: disabled, variant: "outlined", sx: __assign({}, sx), value: (value === null || value === void 0 ? void 0 : value.tag) || '', onChange: function (e) {
            var selectedTag = tags.find(function (tag) { return tag.tag === e.target.value; });
            selectedTag ? onChange(selectedTag) : onChange(undefined);
        }, select: true, slotProps: {
            input: {
                sx: __assign({}, inputProps)
            },
            inputLabel: {
                sx: __assign({}, InputLabelProps)
            },
            select: {
                onOpen: function () { return (skip ? setTags(additionalTags) : loadTags()); },
                MenuProps: {
                    slotProps: {
                        paper: {
                            sx: {
                                width: dimensions.width,
                                maxWidth: dimensions.maxWidth,
                                maxHeight: '14.5rem'
                            },
                            onScroll: function (e) {
                                var listboxNode = e.currentTarget;
                                if (listboxNode.scrollTop + listboxNode.clientHeight >=
                                    listboxNode.scrollHeight) {
                                    loadMoreTags();
                                }
                            }
                        }
                    }
                }
            }
        }, children: [children, tags
                .filter(function (tag) { return tag.tag !== ''; })
                .map(function (tag, index) { return (_jsx(MenuItem, { value: tag.tag, disabled: disabledTags.includes(tag.tag), children: _jsx(ListItemText, { sx: { marginY: '0' }, primaryTypographyProps: {
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        height: '1.25rem'
                    }, primary: tag.displayName }) }, index)); }), !children && tags.length === 0 && (_jsx(MenuItem, { disabled: true, children: _jsx(ListItemText, { sx: { marginY: '0' }, primaryTypographyProps: {
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        height: '1.25rem'
                    }, primary: 'No options' }) })), isFetching && _jsx(Loading, { size: 10 })] }));
};
